<template>
    <UHeader title="NomadRx | Scribe" to="/scribe" :ui="{ wrapper: 'border-none' }">
        <template #logo>
            <div class="flex items-center">
                <p class="tracking-tight">NomadRx</p>
                <UBadge color="primary" variant="soft" class="ml-3">
                    <UIcon name="i-mdi-quill" /><span class="ml-0">Scribe</span>
                </UBadge>
            </div>
        </template>
        <template #right>
            <ScribeLoggedInInfo
                :partner="partner"
                :provider="provider"
            />
            <UDropdown :items="accountItems" mode="click">
                <UButton color="gray" variant="ghost" square icon="i-heroicons-user-solid" />
            </UDropdown>
        </template>
    </UHeader>
</template>

<script setup lang="ts">

const route = useRoute()
const { signOutUser } = useFirebaseAuth();

const partnerStore = usePartnerStore()
const { partner } = partnerStore

const provider = ref(null)

onMounted(async () => {
    provider.value = await $fetchWithToken(
        '/api/v4/provider/self',
        {
            method: 'GET'
        })
})


const accountItems = [
    [{
        label: 'Settings',
        icon: 'i-heroicons-cog-8-tooth',
        disabled: false,
        to: "/scribe/settings"
    }], [{
        label: 'Sign out',
        icon: 'i-heroicons-arrow-left-end-on-rectangle',
        click: async () => {
            console.log('Signing out...')
            await signOutUser();
        }
    }]
]
</script>