<template>
    <UDashboardLayout>
        <UDashboardPage>
            <UDashboardPanel grow>
                <ScribeAppHeader />
                <UDashboardPanelContent>
                    <UContainer class="mt-5 mb-36" :ui="{
                        base: 'w-full', padding: 'px-0 sm:px-6 lg:px-8',
                        constrained: 'max-w-4xl'
                    }">
                        <slot />
                    </UContainer>
                </UDashboardPanelContent>
            </UDashboardPanel>
        </UDashboardPage>
        <!-- Auth loading overlay -->
        <!-- <AuthLoadingOverlay :show="isAuthLoading" :title="authLoadingTitle" :message="authLoadingMessage" /> -->
    </UDashboardLayout>
</template>
<script setup lang="ts">
// Import the auth loading composable
const { isAuthLoading, authLoadingTitle, authLoadingMessage } = useAuthLoading();
</script>